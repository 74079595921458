.contributionSection {
  width: 100%;
  padding: 24px;
  background: var(--background-default);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid var(--outline-default);
  /* min-height: 600px; */
}

.contributionSection hr {
  border-radius: 5px;
  opacity: 0.5;
  width: 40%;
  margin: 2em auto;
}

.terms > p {
  line-height: 1.4;
  padding: 0.5rem 0;
}

.inputs {
  margin-top: var(--space-12);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputs > div {
  width: 275px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

input {
  display: inline-block;
  font-family: var(--font-family-secondary);
  color: var(--foreground-default);
  padding: var(--space-4) var(--space-8);
}

input,
.input {
  background: var(--background-default);
  border: 1px solid var(--outline-default);
  transition: background-color 0.3s ease;
  border-radius: var(--border-radius-default);
}

input:hover,
.input:hover {
  background-color: var(--background-higher);
}

textarea {
  font-family: "Roboto Mono";
  /* display: inline-block; */
  background-color: var(--background-default);
  border: 1px solid var(--outline-default);
  color: var(--foreground-dimmer);
  padding: var(--space-4);
}

input::placeholder {
  color: var(--foreground-dimmest);
}

/* input:required::before {
    content: "*****************";
    color: red;
    z-index: 123123123;
    width: 1000px;
    background: red;
    height: 400px;
    top: 45px;
    right: 0px;
} */

.actionsContainer {
  display: flex;
  margin-top: 24px;
  justify-content: center;
}

.actionsContainer svg {
  display: inline;
}

.signContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.verifyContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: left;
}

.verifyContainer > p {
  width: 100%;
}

.verifyContainer > p:first-child {
  padding-top: 0;
}

.verifyActions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contributionContainer {
  grid-template-columns: 1fr 1fr;
}

.selects {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

label > *,
label p {
  padding-bottom: 0.25rem;
}

.errorContainer {
  margin-top: 12px;
}

ul {
  margin-left: 4px;
}

ul > li:not(:first-child) {
  margin-top: 10px;
}

.metaText {
  font-size: 100%;
}

.signAttribution {
  background: #496581;
  font-size: 1.2rem;
}

.contributionsPreview {
  margin-top: 24px;
}

.pageProgressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageProgress {
  margin: 0 var(--space-12);
  width: 64px;
  height: 4px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100px;
}

.selectedPageProgress {
  background: #ffffff;
}

.twitterSteps {
  grid-template-columns: auto auto;
  grid-gap: 8px;
}

