article {
  counter-reset: footnote-content;
}

.footnote {
  display: inline;
}

.footnote-text {
  text-decoration: underline dotted var(--accent-dimmer) 2px;
}

.footnote::after {
  text-indent: 0;
  content: counter(footnote-content);
  font-size: var(--font-size-default);
  display: inline-block;
  position: relative;
  top: -9px;
  right: -2px;
  color: var(--accent-default);
  box-sizing: border-box;
  background-color: rgba(198, 142, 255, 0);
  border: 1px solid rgba(198, 142, 255, 0);
  border-radius: 2px;
  transition: all 0.4s ease;
  line-height: 0.9rem;
  padding: 0.05rem 0.1rem;
}

.footnote:hover > .footnote-content,
.footnote.expanded > .footnote-content {
  opacity: 1;
  user-select: text;
  max-height: 50vh;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 50%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 1))
  );
}

.footnote:hover::after,
.footnote:hover .footnote-content::before {
  background-color: var(--accent-dimmest);
  border: 1px solid var(--accent-default);
}

p:hover .footnote-content {
  opacity: 0.5;
  max-height: 50vh;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 50%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 1))
  );
}

.footnote-content {
  text-indent: 0;
  opacity: 0.3;
  transition: opacity 0.8s ease, max-height 0.4s ease;
  z-index: 4;
  position: absolute;
  color: white;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 50%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  font-size: 1rem;
  display: inline;

  line-height: 1.5rem;
  max-height: calc(3 * 1.5rem);
  overflow: hidden;
  padding-top: 3px;

  padding-left: 25px;
  right: 4%;
  text-transform: none;
  width: 400px;
  margin-top: -30px;
  user-select: none;
  text-overflow: ellipsis;
}

.footnote-content::after {
  position: absolute;
  content: "...";
  inset-block-end: 0;
  inset-inline-end: -1.5rem;
}

.footnote-content-left {
  left: 5%;
}

.footnote-content-right {
  right: 5%;
}

.footnote-content::before {
  counter-increment: footnote-content;
  content: counter(footnote-content);
  font-size: 1rem;
  display: inline-block;
  position: relative;
  top: -6px;
  right: 5px;
  color: rgb(198, 142, 255);
  box-sizing: border-box;
  background-color: rgba(198, 142, 255, 0);
  border: 1px solid rgba(198, 142, 255, 0);
  border-radius: 2px;
  transition: all 0.4s ease;
  line-height: 0.9rem;
  padding: 0.05rem 0.1rem;
}

.footnote-content-mobile {
  display: none;
}

@media only screen and (max-width: 1650px) {
  .footnote {
    cursor: pointer;
    position: relative;
  }
  .footnote-content {
    width:0px;
    height: 0px;
    opacity: 0 !important;
    pointer-events: none;
  }
  .footnote-content-mobile {
    display: initial;
    text-transform: none;
    position: absolute;
    background-color: var(--background-root);
    font-size: 1rem;
    padding: var(--space-16);
    padding-right: 24px;
    z-index: 4;
    width: 340px;
    top: 32px;
    left: 0;
    border: 1px solid var(--outline-default);
    border-radius: var(--border-radius-8);
    box-shadow: var(--shadow-default);
  }
}

@media only screen and (max-width: 500px) {
  .footnote {
    position: initial;
  }
  article > p {
    position: relative;
  }
  .footnote-content {
    opacity: 0 !important;
  }
  .footnote-content-mobile {
    position: absolute;
    width: 90vw;
    left: -5vw;
    top: 100%;
  }
}
