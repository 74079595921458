.hero {
  color: white;
  height: 90vh;
  position: relative;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 2s;
}

.heroAttribution {
  margin-left: 30px;
}

.versesLogoContainer {
  backdrop-filter: blur(4px);
}
