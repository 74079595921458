.carouselOverflowContainer {
  overflow-x: auto;
  width: 100%;
  scroll-snap-type: x mandatory;
  transition: -webkit-mask-image 0.5s ease;
  -webkit-mask-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 95%,
    rgba(0, 0, 0, 0) 100%
  );
}

.carouselOverflowContainer.left {
  -webkit-mask-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 95%,
    rgba(0, 0, 0, 0) 100%
  );
}

.carouselOverflowContainer.right {
  -webkit-mask-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 100%
  );
}

.carouselOverflowContainer::-webkit-scrollbar {
  display: none;
}

/* todo consolidate this with the other glass buttons */
.carouselArrowButton {
  padding: 10px;
  border: 0;
  text-decoration: none;
  border-radius: 60px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .carouselArrowButton {
    display: none;
  }
}

.carouselArrowButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
