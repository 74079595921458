/* inspo: https://codepen.io/chriscoyier/pen/XWKEVLy */

.contenteditableSpan {
    display:inline-block;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    background: white;
    min-width: 200px;
    padding: 0 2px;
}

.grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
    width: 100%;
}

.grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}

.grow-wrap > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
}

.grow-wrap > textarea,
.grow-wrap::after {
    /* Identical styling required!! */
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 0.5rem;
    font: inherit;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
}
